@import "./Assets/fonts/inter.css";
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: var(--ion-color-secondary);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary-shade);
}
::-webkit-scrollbar-thumb:active {
  background: var(--ion-color-primary);
}
::-webkit-scrollbar-track {
  background: #eaeaea;
}
::-webkit-scrollbar-track:hover {
  background: #eaeaea;
}
::-webkit-scrollbar-track:active {
  background: #eaeaea;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.App {
  overflow: hidden;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
b, strong {
  font-weight: 600;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ion-text-color);
}

button,
input,
textarea {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #313467;
  --ion-color-primary-rgb: 7, 63, 157;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-shade: #202250;
  --ion-color-primary-tint: #4c4f8b;

  /** secondary **/
  --ion-color-secondary: #00afee;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-shade: #0093c9;
  --ion-color-secondary-tint: #2dc7ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #62c235;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-shade: #41a016;
  --ion-color-success-tint: #90e768;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-contrast: #000000;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #121212;

  --ion-text-color: #717280;

  --ion-text-dark: #404755;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}

button {
  &:disabled {
    opacity: 0.5 !important;
  }
}

.hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
}

.inactive {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .content {
    width: 500px;
    max-width: 90%;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    text-align: center;
    h3 {
      font-size: 32px;
      font-weight: bold;
      color: var(--ion-text-color);
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      color: var(--ion-text-color);
      font-weight: 500;
      margin-bottom: 50px;
    }

    button {
      width: 240px;
      height: 44px;
      background: var(--ion-color-primary);
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      color: white;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.container {
  display: block;
  width: 100%;
  max-width: 1250px;
  padding: 0 1rem;
  margin: 0 auto;
}

form {
  .check {
    margin-top: 15px;
    position: relative;
    input {
      position: absolute;
      left: 9px;
      top: 9px;
      width: 1px;
      height: 1px;
      opacity: 0.1;
      pointer-events: none;

      + label {
        cursor: pointer;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        text-align: left;
        color: var(--ion-text-color);
        display: grid;
        grid-template-columns: 25px 1fr;
        gap: 10px;
        text-align: justify;

        span {
          width: 25px;
          height: 25px;
          border: 2px solid var(--ion-text-color);
          border-radius: 5px;
          position: relative;

          &::before {
            content: "";
            width: 15px;
            height: 15px;
            background-color: var(--ion-text-color);
            position: absolute;
            top: 3px;
            left: 3px;
            border-radius: 3px;
            transition: 0.3s;
            opacity: 0;
          }
        }
      }

      &:checked {
        + label {
          span {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.form-group {
  position: relative;
  margin-top: 20px;
  input,
  textarea {
    background: #ffffff;
    border: 1px solid #D5F2FD;
    box-sizing: border-box;
    border-radius: 6px;
    height: 36px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--ion-text-color);
    padding-left: 15px;
    + label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: -0.025em;
      color: var(--ion-text-color);
      opacity: 1;
      position: absolute;
      left: 15px;
      top: 12px;
      transition: 0.3s;
      pointer-events: none;
    }

    &:valid,
    &:focus,
    &:read-only {
      + label {
        top: -15px;
        left: 0;
        font-size: 12px;
        opacity: 1;
        text-transform: lowercase;
        color: var(--ion-text-color);
      }
    }
  }

  textarea {
    height: 70px;
    resize: none;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: unset;
  }
}
